import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "applying-the-rules"
    }}>{`Applying the rules`}</h1>
    <h3 {...{
      "id": "be-consistent-consistent-consistent"
    }}>{`Be consistent, consistent, consistent!`}</h3>
    <p>{`The best way to get the most out of all your hard work in creating the rules is to be consistent in maintaining the rules and carrying out the consequences. Uphold the rules even if it makes you unpopular!`}</p>
    <p>{`If you have a partner, it is helpful for the two of you to support each other regarding applying the rules to your family. Be united and consistent in following through with the rules.`}</p>
    <Paper mdxType="Paper">
  <h3>When upholding the rules:</h3>
  <ul>
    <li>Try to stay calm – over-reacting can be counterproductive, and provides a poor model for how to behave.</li>
    <li>Explain that the rules and consequences are there for your teenager’s safety, not to make them miserable.</li>
    <li>
      Focus on educating your teenager about why their behaviour was wrong. Explain that you disapprove of the
      behaviour, not your teenager themselves. This communicates that you still love them.
    </li>
    <li>Do not use consequences that cause your teenager to feel embarrassed or humiliated.</li>
  </ul>
    </Paper>
    <br />
    <h3 {...{
      "id": "natural-consequences"
    }}>{`Natural consequences`}</h3>
    <p>{`Some actions have natural consequences, so you may not have to do anything else to enforce the rules.`}</p>
    <p>{`For example, allowing your teenager to face a teacher and explain that they did not do their homework because they were gaming online all night.`}</p>
    <h3 {...{
      "id": "lead-by-example"
    }}>{`Lead by example`}</h3>
    <p>{`Follow rules and laws yourself in order to be a good role model for your teenager.`}</p>
    <SingleImage smallGridSize={10} gridSize={10} src='/images/m3/16-02.svg' mdxType="SingleImage" />
    <SingleImage smallGridSize={10} gridSize={10} src='/images/m3/16-svg-01.svg' mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      